import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {
    BoxInContainerSX,
    Center,
    ContainerSX,
    DescriereSectiuneSX,
    SectiuneTitluFontSX,
    UnderlineTextSX
} from "../App.styles";
import {Box, Card, CardContent, CardMedia, Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import image1 from "../imaginiServicii/sheet_metal.png";
import image2 from "../imaginiServicii/rolling_machine2.png";
import image3 from "../imaginiServicii/poza_pt_subansamblu.jpg";
import image4 from "../imaginiServicii/structura_metalica_edited.jpg";
import image5 from "../imaginiServicii/cnc_machine.png";
import image6 from "../imaginiServicii/guillotine_shear_machine.png";
import Zoom from '@mui/material/Zoom';
import {useInView} from 'react-intersection-observer';

// import LazyLoad from 'react-lazyload';


const services = [
    {
        title: 'Prelucrări table', // 1
        description: [
            'Debitări de precizie ale tablelor cu grosimi până la 25 mm pe mașini de debitat cu plasmă CNC.',
            'Debitări pe ghilotina CNC ale tablelor cu grosimi până la 10 mm și lungimea de până la 3 m.',
            'Îndoiri table cu grosimi de până la 10 mm și lungimi de până la 3m pe presă hidraulică Abkant cu CNC.',
        ],
        image: image1
    },
    {
        title: 'Roluirea țevilor', // 2
        description: [
            'Roluit profile cu diferite secțiuni si dimensiuni pe mașină de roluit cu CNC.'
        ],
        image: image2
    },
    {
        title: 'Confecții metalice', // 3
        description: [
            'Fabricarea subansamblurilor sudate din oțel, oțel inoxidabil sau aluminiu de către sudori autorizați.',
        ],
        image: image3
    },
    {
        title: 'Structuri metalice', // 4
        description: [
            'Confecții și construcții structuri metalice pentru hale.',
            'Grătare metalice zincate pentru platforme, scări sau pasarele.',
            'Porți, garduri, copertine, balustrade și scări.'
        ],
        image: image4
    },
    {
        title: 'Prelucrări mecanice', // 5
        description: [
            'Servicii de prelucrare mecanică prin așchiere (strunjire, frezare).'
        ],
        image: image5
    },
    {
        title: 'Servicii diverse', // 6
        description: [
            'Relocări de echipamente și utilaje.',
            'Închirieri de stivuitoare și nacele.',
            'Avem colaborări în domeniile: zincării, sablării și vopsirii în câmp electrostatic.',
        ],
        image: image6
    },
];

const ServiceCard = ({service, index}) => {
    const {ref, inView} = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <Grid item key={service.title} xs={12} md={4} ref={ref}>
            <Zoom in={inView} style={{transitionDelay: inView ? `${index * 0.2}s` : '0s'}}>
                <Card sx={{height: 500, maxWidth: 345, backgroundColor: '#EEEEEE'}}>
                    <CardMedia
                        component="img"
                        height="200"
                        image={service.image}
                        alt={service.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" color="#393E46"
                                    style={DescriereSectiuneSX}>
                            {service.title}
                        </Typography>
                        <ul>
                            {service.description.map((desc, index) => (
                                <li key={index}>
                                    <Typography variant="body2" color="text.secondary" textAlign="left"
                                                style={DescriereSectiuneSX}>
                                        {desc}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </CardContent>
                </Card>
            </Zoom>
        </Grid>
    );
};

export const Servicii = forwardRef((props, ref) => {
    const [isRowOneRendered, setIsRowOneRendered] = useState(false);

    useEffect(() => {
        // Simulate slight delay after first row renders (replace with actual logic)
        setTimeout(() => setIsRowOneRendered(true), '700'); // Adjust delay as needed
    }, []);

    const serviciiRef = useRef(null);

    useEffect(() => {
        if (serviciiRef.current) {
            console.log('Height of Servicii:', serviciiRef.current.offsetHeight);
        }
    }, [serviciiRef.current]);

    return <div ref={ref} style={{...Center}}>
        <Container
            sx={{...ContainerSX}}
            maxWidth={false}
        >
            <Box
                sx={{...BoxInContainerSX, height: '1152.020px'}} //spacing * 8px = gap in caz ca mai schimbam la el
            >
                <Typography component="h2" variant="h3" color="text.primary" style={SectiuneTitluFontSX}>
                    Servicii
                </Typography>
                <Box sx={UnderlineTextSX}/>
                {/* First Row */}
                <Box sx={{marginBottom: `-64px`}}>
                    <Grid container spacing={2}>
                        {services.slice(0, Math.ceil(services.length / 2)).map((service, index) => (
                            <ServiceCard service={service} index={index} key={service.title}/>
                        ))}
                    </Grid>
                </Box>

                {/* Second Row with conditional rendering based on state */}
                <Box>
                {isRowOneRendered && (
                    <Grid container spacing={2}>
                        {services.slice(Math.ceil(services.length / 2)).map((service, index) => (
                            <ServiceCard service={service} index={index} key={service.title}/>
                        ))}
                    </Grid>
                )}
                </Box>
            </Box>
        </Container>
    </div>
})