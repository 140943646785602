import React, {forwardRef} from 'react'
import {BoxInContainerSX, Center, ContainerSX, SectiuneTitluFontSX, UnderlineTextSX} from "../App.styles";
import {Box, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Map from "./Map";
import DateContact from "./DateContact";
import ContactForm from "./ContactForm";
import {useNavigate} from "react-router-dom";

export const Contact = forwardRef(({ acasaRef }, ref) => {

    const navigate = useNavigate();
    const defaultCenter = { lat: 46.81668395634707, lng: 23.60013690013213 };
    const defaultZoom = 12.25;

    const handleClick = () => {
        // if (acasaRef && acasaRef.current) {
        //     acasaRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
        navigate("/termenisiconditii")
    };

    return <div ref={ref} style={Center}>
        <Container
            sx={{...ContainerSX, backgroundColor: '#EEEEEE'}}
            maxWidth={false}
        >
            <Box
                sx={BoxInContainerSX}
            >
                <Typography component="h2" variant="h3" color="text.primary" style={SectiuneTitluFontSX}>
                    Contact
                </Typography>
                <Box sx={UnderlineTextSX}/>
                <Grid container>
                    <Grid item xs={12}>
                        <Map center={defaultCenter} zoom={defaultZoom}/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{textAlign: "left"}}>
                        <DateContact/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{textAlign: "left"}}>
                        <ContactForm/>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{display: "flex", flexDirection: "row", mb: "-100px", gap: "50px", alignItems: "center"}}>
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', cursor: 'pointer'}}
                    onClick={handleClick}
                    onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                    onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                    Termeni și condiții
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', cursor: 'pointer'}}
                    onClick={handleClick}
                    onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                    onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                    GDPR
                </Typography>
            </Box>
        </Container>
    </div>
})