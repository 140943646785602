import React, {useState, useEffect, useRef} from 'react';
import CountUp from 'react-countup';
import {Grid, Typography} from '@mui/material';
import {DescriereSectiuneSX} from "../App.styles";

export const CompanyStats = () => {
    const [yearEstablished, setYearEstablished] = useState(0);
    const [projectsCompleted, setProjectsCompleted] = useState(0);
    const [contractors, setContractors] = useState(0);
    const [awards, setAwards] = useState(0);
    const statsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                // Simulate loading or calculate actual values
                const currentYear = new Date().getFullYear();
                const yearsEstablished = currentYear - 2005;
                setYearEstablished(yearsEstablished);
                setProjectsCompleted(5000);
                setContractors(1000);
                setAwards(1000);

                observer.disconnect(); // Stop observing after the animation starts
            }
        });

        if (statsRef.current) {
            observer.observe(statsRef.current);
        }
    }, [statsRef]);

    return <div style={{backgroundColor: "#00ADB5", padding: "40px", paddingLeft:"200px", paddingRight:"200px"}}>
        <Grid container justifyContent="center" alignItems="center" ref={statsRef}>
            <Grid container xs={4} sx={{ borderRight: '1px solid #EEEEEE' }}> {/* Added white background and border */}
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ color: '#EEEEEE', ...DescriereSectiuneSX }}>
                        <CountUp end={yearEstablished} duration={2.5} /> {' '}Ani
                    </Typography>
                    <Typography variant="body2" fontSize="1.4rem" sx={{ color: '#EEEEEE', ...DescriereSectiuneSX }}>
                        Experiență
                    </Typography>
                </Grid>
            </Grid>
            <Grid container xs={4} sx={{borderRight: '1px solid #EEEEEE', borderLeft: '1px solid #EEEEEE' }}> {/* Added white background and border */}
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ color: '#EEEEEE', ...DescriereSectiuneSX }}>
                        <CountUp end={projectsCompleted} duration={1.5} /> +
                    </Typography>
                    <Typography variant="body1" fontSize="1.4rem" sx={{ color: '#EEEEEE', ...DescriereSectiuneSX }}>
                        Proiecte finalizate
                    </Typography>
                </Grid>
            </Grid>
            <Grid container xs={4} sx={{borderLeft: '1px solid #EEEEEE' }}> {/* Added white background */}
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ color: '#EEEEEE', ...DescriereSectiuneSX }}>
                        <CountUp end={contractors} duration={1.5} /> +
                    </Typography>
                    <Typography variant="body1" fontSize="1.4rem" sx={{ color: '#EEEEEE', ...DescriereSectiuneSX }}>
                        Clienți mulțumiți
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </div>
}

export default CompanyStats;
