import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {Center, ContainerSX, SectiuneTitluFontSX, UnderlineTextSX} from "../App.styles";
import {Button, Container, Fade, Stack, Zoom} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from '@mui/material/Modal';
import {makeStyles} from "@mui/styles";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

function importAll(r, category) {
    return r.keys().map((item, index) => ({
        src: r(item),
        title: `${category} - ${index}`
    }));
}

const useStyles = makeStyles((theme) => ({
    modalImage: {
        width: '100%',
        height: 'auto',
        maxHeight: '80vh',
        maxWidth: '80vw',
        objectFit: 'contain',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function TitlebarBelowMasonryImageList() {
    const category1Images = importAll(require.context('../imaginiProiecte/Category1', false, /\.(png|jpe?g|svg)$/), 'Category 1'); // aici poate sa fie gen Balustrade inox etc.
    const category2Images = importAll(require.context('../imaginiProiecte/Category2', false, /\.(png|jpe?g|svg)$/), 'Category 2');
    const category3Images = importAll(require.context('../imaginiProiecte/Category3', false, /\.(png|jpe?g|svg)$/), 'Category 3');
    const category4Images = importAll(require.context('../imaginiProiecte/Category4', false, /\.(png|jpe?g|svg)$/), 'Category 4');
    const category5Images = importAll(require.context('../imaginiProiecte/Category5', false, /\.(png|jpe?g|svg)$/), 'Category 5');

    const [images, setImages] = useState(category1Images);
    const [selectedCategory, setSelectedCategory] = useState('category1');

    const handleButtonClick = (category) => {
        setSelectedCategory(category);
        switch (category) {
            case 'category1':
                setImages(category1Images);
                break;
            case 'category2':
                setImages(category2Images);
                break;
            case 'category3':
                setImages(category3Images);
                break;
            case 'category4':
                setImages(category4Images);
                break;
            case 'category5':
                setImages(category5Images);
                break;
            default:
                setImages([...category1Images, ...category2Images, ...category3Images, ...category4Images, ...category5Images]);
                break;
        }
    }

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [originalOverflow, setOriginalOverflow] = useState(null);

    const handleOpen = (index) => {
        setSelectedImageIndex(index);
        setOpen(true);
        setOriginalOverflow(document.body.style.overflow);
        document.body.style.overflow = 'hidden';
    };

    const handleNext = (e) => {
        e.stopPropagation();
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = (e) => {
        e.stopPropagation();
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            document.body.style.overflow = originalOverflow;
        }, 0.0001); // Asta face ca scrollbaru sa apara dupa ce dai exit la poza
    };
    const filteredImages = selectedCategory === 'all' ? images : images.filter(image => image.category === selectedCategory);


    return (
        <div style={{backgroundColor: '#EEEEEE', minHeight: '100vh'}}>
            <div style={Center}>
                <Container
                    sx={{...ContainerSX, backgroundColor: '#EEEEEE'}}
                    maxWidth={false}
                    padding={0}
                >
                    <Typography component="h2" variant="h3" color="text.primary" style={SectiuneTitluFontSX}>
                        Proiecte
                    </Typography>
                    <Box sx={UnderlineTextSX}/>

                    <Stack spacing={2} direction="row">
                        <Button
                            variant="contained"
                            onClick={() => handleButtonClick('category1')}
                            sx={{
                                backgroundColor: selectedCategory === 'category1' ? 'transparent' : '#00ADB5',
                                color: selectedCategory === 'category1' ? '#00ADB5' : '#EEEEEE',
                                '&:hover': {
                                    backgroundColor: selectedCategory === 'category1' ? 'transparent' : '#00ADB5',
                                    color: selectedCategory === 'category1' ? '#00ADB5' : '#EEEEEE',
                                },
                            }}
                        >
                            Category 1
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleButtonClick('category2')}
                            sx={{
                                backgroundColor: selectedCategory === 'category2' ? 'transparent' : '#00ADB5',
                                color: selectedCategory === 'category2' ? '#00ADB5' : '#EEEEEE',
                                '&:hover': {
                                    backgroundColor: selectedCategory === 'category2' ? 'transparent' : '#00ADB5',
                                    color: selectedCategory === 'category2' ? '#00ADB5' : '#EEEEEE',
                                },
                            }}
                        >
                            Category 2
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleButtonClick('category3')}
                            sx={{
                                backgroundColor: selectedCategory === 'category3' ? 'transparent' : '#00ADB5',
                                color: selectedCategory === 'category3' ? '#00ADB5' : '#EEEEEE',
                                '&:hover': {
                                    backgroundColor: selectedCategory === 'category3' ? 'transparent' : '#00ADB5',
                                    color: selectedCategory === 'category3' ? '#00ADB5' : '#EEEEEE',
                                },
                            }}
                        >
                            Category 3
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleButtonClick('category4')}
                            sx={{
                                backgroundColor: selectedCategory === 'category4' ? 'transparent' : '#00ADB5',
                                color: selectedCategory === 'category4' ? '#00ADB5' : '#EEEEEE',
                                '&:hover': {
                                    backgroundColor: selectedCategory === 'category4' ? 'transparent' : '#00ADB5',
                                    color: selectedCategory === 'category4' ? '#00ADB5' : '#EEEEEE',
                                },
                            }}
                        >
                            Category 4
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleButtonClick('category5')}
                            sx={{
                                backgroundColor: selectedCategory === 'category5' ? 'transparent' : '#00ADB5',
                                color: selectedCategory === 'category5' ? '#00ADB5' : '#EEEEEE',
                                '&:hover': {
                                    backgroundColor: selectedCategory === 'category5' ? 'transparent' : '#00ADB5',
                                    color: selectedCategory === 'category5' ? '#00ADB5' : '#EEEEEE',
                                },
                            }}
                        >
                            Category 5
                        </Button>
                    </Stack>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}>
                        <ImageList variant="masonry" cols={3} gap={10}>
                            {images.map((image, index) => (
                                <ImageListItem key={image.src} onClick={() => handleOpen(index)}>
                                    <Fade in={true} timeout={500}>
                                        <img
                                            src={image.src}
                                            alt={image.title}
                                            loading="lazy"
                                            style={{
                                                width: '372px',
                                                // height: `${Math.random() * (500 - 300) + 300}px`, // random asa la sanki
                                                height: 'auto',
                                                objectFit: 'cover',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </Fade>
                                    <ImageListItemBar position="below" title={image.title}/>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                    <Modal open={open} onClose={handleClose}>
                        <Box onClick={handleClose} sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'row', // Change to row for image and buttons
                                width: '100%',
                                height: 'auto',
                                padding: '0 5%',
                            }}>
                                <Box
                                    height={55}
                                    width={30}
                                    my={4}
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                        cursor: 'pointer',
                                        border: '2px solid grey',
                                        bgcolor: '#393E46',
                                        '&:hover': {
                                            bgcolor: 'rgba(57,62,70,0.67)',
                                        },
                                    }}
                                    onClick={(e) => handlePrev(e)}
                                >
                                    <ArrowBackIosRoundedIcon onClick={(e) => handlePrev(e)} style={{cursor: 'pointer', color: 'white'}}/>
                                </Box>
                                <img
                                    src={images[selectedImageIndex]?.src}
                                    alt="Selected"
                                    className={classes.modalImage}
                                    style={{width: 'auto', height: 'auto'}}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                <Box
                                    height={55}
                                    width={30}
                                    my={4}
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                        cursor: 'pointer',
                                        border: '2px solid grey',
                                        bgcolor: '#393E46',
                                        '&:hover': {
                                            bgcolor: 'rgba(57,62,70,0.67)',
                                        },
                                    }}
                                    onClick={(e) => handleNext(e)}
                                >
                                <ArrowForwardIosRoundedIcon onClick={(e) => handleNext(e)} style={{cursor: 'pointer', color: 'white'}}/>
                                </Box>
                            </Box>
                            <Box sx={{  // Wrap image previews in another Box
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                                // padding: '10px',
                                gap: '10px',
                                // marginTop: '20px',
                                whiteSpace: 'nowrap',
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                width: '100%',
                            }}>
                                    {images.map((image, index) => (
                                        <img
                                            key={image.src}
                                            src={image.src}
                                            alt={image.title}
                                            style={{
                                                width: '80px',
                                                height: '80px',
                                                objectFit: 'cover',
                                                cursor: 'pointer',
                                                border: selectedImageIndex === index ? '2px solid #00ADB5' : 'none',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedImageIndex(index);
                                                setOpen(true);
                                            }}
                                        />
                                    ))}
                                </Box>
                        </Box>
                    </Modal>
                </Container>
            </div>
        </div>
    );
}