import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Map = ({ center, zoom }) => {
    return (
        <LoadScript googleMapsApiKey="AIzaSyAER-T4srtgxjZorUY03HsdEfsnDTyPCeU">
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '500px',
                    margin: 'auto',
                }}
                center={center}
                zoom={zoom}
            >
                {/* Marker should appear here */}
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
};

export default Map;
