import React from 'react';
import {Typography, Box} from "@mui/material";
import {
    BoxInContainerSXV2,
    Center,
    TermeniSiCoditiiBox,
    UnderlineTextSXV2
} from "../App.styles";

export const TermeniSiConditii = () => {

    return (
        <div style={Center}>
            <Box sx={TermeniSiCoditiiBox}>
                <Box
                    sx={BoxInContainerSXV2}
                >
                    <Typography variant="h4" color="text.primary" sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'center'}}>
                        Termeni și condiții
                    </Typography>
                    <Box sx={UnderlineTextSXV2}/>
                </Box>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify', mt: "64px"}}
                >
                    Vă mulțumim pentru interesul pe care îl acordaţi serviciilor ALLPRO SERVICE S.R.L. Pentru noi,
                    având în vedere relația frumoasă pe care o avem cu Dvs.,
                    este esenţial să vă oferim protecția datelor cu caracter personal pe care ni le oferiţi.
                    În ceea ce priveşte datele dvs. cu caracter personal confidenţialitatea acestora este deosebit
                    de importantă pentru noi.
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif'}}
                >
                    CINE SUNTEM?
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    ALLPRO SERVICE  S.R.L este o societate organizată și care funcționează în
                    conformitate cu legile din România, cu sediul în Cluj Napoca,
                    Str. Constantin Brâncoveanu, Nr. 53, înregistrată la Registrul
                    Comerțului sub nr. J12/758/2005, cod unic de înregistrare RO17294520.
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif'}}
                >
                    CE DATE PRELUCRĂM?
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    În vederea livrării bunurilor și serviciilor comandate, confirmării Comenzii,
                    informării Dvs. privind stadiul Comenzii, oferirii de răspunsuri la reclamațiile plasate,
                    evaluării bunurilor și serviciilor oferite, precum și a exercitării unor activități comerciale,
                    de promovare a bunurilor și serviciilor, de marketing, publicitate, de dezvoltare,
                    de cercetare de piață, statistică, monitorizare a vânzărilor și comportamentului
                    de cumpărare a clienților, activități administrative și de media, sunteți de acord
                    să ne încredințați următoarele date cu caracter personal: nume, prenume, cnp,
                    adresa de domiciliu, adresa de e-mail, adresa de ip, număr de telefon. Vă rugăm să nu
                    ne furnizați alte date cu caracter personal decât cele strict necesare scopului
                    precizat în această politică.
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif'}}
                >
                    CARE ESTE SCOPUL ȘI TEMEIUL JURIDIC AL PRELUCRĂRII?
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    Datele cu caracter personal vor fi furnizate cu consimțământul Dvs. sunt și vor fi
                    colectate și prelucrate de noi numai în scopurile pe care le vom prezenta în rândurile de mai jos.
                    <br/><br/>
                    Menționăm faptul că prelucrarea datelor cu caracter personal se realizează în temeiul
                    consimțământului Dvs. în acest sens (art. 6 alin. (1) lit. (a) din Regulamentul General
                    privind Protecția Datelor, numit în cele ce urmează Regulamentul.
                    <br/><br/>
                    Situațiile în care ALLPRO SERVICE S.R.L va colecta și prelucra datele Dvs. cu caracter
                    personal și scopurile în vederea cărora aceste date vor fi prelucrate:
                    <br/><br/>
                    Formular de contact pe site-ul https://www.allproservice.ro si alte sub domenii sau
                    micro site-uri ale acestor domenii web.
                    <br/><br/>
                    Colectarea datelor cu caracter personal în acest caz are scopul principal de a oferi răspuns
                    la orice întrebare ai avea, dându-ne posibilitatea sa te contactam si sa îți oferim
                    soluțiile solicitate. Aceste date (ex. adresă e-mail, număr telefon, nume) sunt necesare
                    pentru o bună comunicare între societatea noastră și Dvs., în calitate de potenţial client.
                    Totodată, cu ajutorul unor coduri de tracking instalate pe platforma noastră de la diverși
                    furnizori de servicii de analize și statistici, sunt colectate date precum adresa de ip,
                    durata de utilizare a unei pagini, paginile vizitate într-un anumit interval de timp.
                    Toate aceste informații colectate au ca scop exclusiv: oferirea unei experienţe
                    optimizate și îmbunătățite de fiecare dată privind funcționarea platformei noastre.
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif'}}
                >
                    CINE ARE ACCES LA DATELE DVS.?
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    Vă aducem la cunoștință că în scopul funcționării eficiente a platformei,
                    utilizăm diferite servicii de la furnizori externi sau interni
                    (”persoane împuternicite de operator”), cărora le putem transmite o parte din datele
                    cu caracter personal colectate de la Dvs., cu mențiunea expresă că vom apela doar la
                    persoane împuternicite care oferă garanţii suficiente pentru punerea în aplicare a
                    unor măsuri tehnice şi organizatorice adecvate, astfel încât prelucrarea să respecte
                    cerinţele prevăzute în Regulament şi să asigure protecţia drepturilor Dvs. Categoriile
                    de servicii pentru care colaborăm cu furnizorii mai sus menționați și datele cu caracter
                    personal transferate acestora sunt următoarele:
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify', ml: "64px"}}
                >
                    1. SMTP (comunicare email) – adresa de e-mail;
                    <br/>
                    2. Servicii newsletter – adresa de e-mail, nume, prenume, număr de telefon;
                    <br/>
                    3. Servicii de facturare – nume, prenume, adresa de e-mail, număr de telefon, adresă;
                    <br/>
                    4. Servicii de suport / project management – nume, prenume, adresa de e-mail, număr de telefon;
                    <br/>
                    5. Servicii de curierat/poștă – nume, prenume, adresa de e-mail, număr de telefon, adresă;
                    <br/>
                    6. Servicii de trimite SMS – numărul de telefon;
                    <br/>
                    7. Servicii de procesare plăți online – nume, prenume, adresa de e-mail, număr de telefon, adresă;
                    <br/>
                    8. Servicii de comentarii – nume, prenume, e-mail;
                    <br/>
                    9. Servicii de contabilitate – nume, prenume, adresă, cnp, date fiscale firma;
                    <br/>
                    10. Servicii juridice – nume, prenume, adresă, cnp;
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif'}}
                >
                    CÂT TIMP SUNT STOCATE DATELE DVS.?
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    Datele cu caracter personal furnizate de Dvs. vor fi prelucrate şi stocate pe perioada
                    derulării relațiilor contractuale cu Dvs., precum și ulterior, pentru o durată rezonabilă,
                    care nu poate fi mai scurtă decât termenul general de prescripție.
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif'}}
                >
                    CARE SUNT DREPTURILE DVS.?
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    În calitate de persoană vizată, aveți următoarele drepturi:
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify', ml: "64px"}}
                >
                    • dreptul de acces la date – aveți dreptul de a obține din partea ALLPRO SERVICES.R.L
                    o confirmare că se prelucrează sau nu date cu caracter personal care vă privesc,
                    precum și acces la datele respective; totodată, puteți obține o copie a datelor cu caracter
                    personal furnizate societății noastre și care fac obiectul prelucrării; pentru orice alte copii
                    solicitate de Dvs., ALLPRO SERVIE S.R.L poate percepe o taxă rezonabilă, bazată pe costurile
                    administrative; în cazul în care introduceți cererea în format electronic și cu excepția cazului
                    în care solicitați un alt format, informațiile vă vor fi furnizate într-un format electronic
                    utilizat în mod curent;
                    <br/><br/>
                    • dreptul la rectificare – aveți dreptul de a obține rectificarea de către ALLPRO SERVICE S.R.L a
                    datelor cu caracter personal inexacte, precum și dreptul de a obține completarea datelor cu caracter
                    personal care sunt incomplete; ALLPRO SERIVES.R.L va comunica fiecărui destinatar căruia i-au fost
                    divulgate datele cu caracter personal orice rectificare a acestora, cu excepția cazului în care
                    acest lucru se dovedește imposibil sau presupune eforturi disproporționate și vă va informa cu
                    privire la acești destinatari, dacă veți solicita acest lucru;
                    <br/><br/>
                    • dreptul la ștergerea datelor – aveți dreptul de a obține din partea ALLPRO SERVICE S.R.L ștergerea
                    datelor cu caracter personal care vă privesc, fără întârzieri nejustificate, iar ALLPRO SERVICE
                    S.R.L are obligația de a șterge datele respective, atunci când: (i) datele cu caracter personal nu
                    mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate; (ii)
                    vă retrageți consimțământul pe baza căruia are loc prelucrarea și nu există niciun alt temei juridic
                    pentru prelucrare; (iii) vă opuneți prelucrării și nu există motive legitime care să prevaleze în
                    ceea ce privește prelucrarea; (iv) datele cu caracter personal au fost prelucrate ilegal; (v) datele
                    cu caracter personal trebuie șterse pentru respectarea unei obligații legale ce revine ALLPRO
                    SERVICE S.R.L în temeiul dreptului Uniunii sau al legislației române; ALLPRO SERVICE S.R.L va
                    comunica fiecărui destinatar căruia i-au fost divulgate datele cu caracter personal orice ștergere a
                    acestora, cu excepția cazului în care acest lucru se dovedește imposibil sau presupune eforturi
                    disproporționate și vă va informa cu privire la acești destinatari, dacă veți solicita acest lucru;
                    <br/><br/>
                    • dreptul la restricționarea prelucrării – aveți dreptul de a obține din partea ALLPRO SERVICE S.R.L
                    restricționarea prelucrării în cazul în care vă aflați într-unul din următoarele cazuri: (i)
                    contestați exactitatea datelor, pentru perioada care îi permite ALLPRO SERVICE S.R.L să verifice
                    exactitatea datelor; (ii) prelucrarea este ilegală, iar Dvs. vă opuneți ștergerii datelor cu
                    caracter personal, solicitând în schimb restricționarea utilizării lor; (iii) ALLPRO SERVICE S.R.L
                    nu mai are nevoie de datele cu caracter personal în scopul prelucrarii, dar Dvs. le solicitați
                    pentru constatarea, exercitarea sau apărarea unui drept în instanță; sau (iv) v-ați opus prelucrării
                    pentru intervalul de timp în care se verifică dacă drepturile legitime ale ALLPRO SERVICE S.R.L
                    prevalează asupra drepturilor Dvs.; ALLPRO SERVICE S.R.L va comunica fiecărui destinatar căruia i-au
                    fost divulgate datele cu caracter personal orice restricționare a prelucrării, cu excepția cazului
                    în care acest lucru se dovedește imposibil sau presupune eforturi disproporționate și vă va informa
                    cu privire la acești destinatari, în cazul în care veți solicita acest lucru;
                    <br/><br/>
                    • dreptul la portabilitatea datelor – aveți dreptul de a primi datele Dvs. cu caracter personal, în
                    modul în care au fost furnizate către ALLPRO SERVICE S.R.L, într-un format structurat, utilizat în
                    mod curent și care poate fi citit automat și de a transmite aceste date altui operator, în anumite
                    condiții; pentru exercitarea acestui drept, aveți dreptul ca datele cu caracter personal să fie
                    transmise direct de la ALLPRO SERVICE S.R.L la alt operator atunci când acest lucru este fezabil din
                    punct de vedere tehnic;
                    <br/><br/>
                    • dreptul la opoziție – aveți dreptul de a vă opune, din motive legate de situația particulară în
                    care vă aflați, în orice moment, prelucrării datelor Dvs. cu caracter personal, inclusiv creării de
                    profiluri pe baza respectivelor dispoziții;
                    <br/><br/>
                    • dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv
                    crearea de profiluri;
                    <br/><br/>
                    • dreptul de a depune o plângere în fața Autorității Naționale de Supraveghere a Prelucrării Datelor
                    cu Caracter Personal;
                    <br/><br/>
                    • dreptul de a vă retrage consimțământul în orice moment, fără a afecta legalitatea prelucrării
                    datelor cu caracter personal efectuate de ALLPRO SERVICE S.R.L pe baza consimțământului înainte de
                    retragerea acestuia.
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif'}}
                >
                    CUM AVEȚI ACCES LA DATELE DVS.?
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    Pentru orice întrebări sau cereri referitoare la datele cu caracter personal pe care ni le-ați
                    transmis, inclusiv în vederea exercitării drepturilor pe care le aveți, astfel cum sunt
                    acestea menționate în cadrul secțiunii anterioare, ne puteți contacta la adresa: Cluj-Napoca,
                    str. Constantin Brancoveanu, nr. 53 jud. Cluj, sau email: allproservic@gmail.com.
                    <br/><br/>
                    ALLPRO SERVICE S.R.L se obligă să vă furnizeze informațiile solicitate de Dvs. în format electronic
                    atunci acest lucru când este posibil, cu excepția cazului în care solicitați un alt format.
                    <br/><br/>
                    Informațiile furnizate, precum și orice comunicare și măsuri luate în cadrul exercitării Dvs. a
                    drepturilor de mai sus sunt oferite de ALLPRO SERVICE S.R.L gratuit. Astfel, în cazul în care
                    cererile pe care Dvs. le formulaţi sunt considerate nefondate sau excesive din cauza unui caracter
                    repetitiv, ALLPRO SERVICE S.R.L poate:
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify', ml: "64px"}}
                >
                    (i) fie să perceapă o taxă, ținând cont de costurile administrative pentru furnizarea informațiilor
                    sau a comunicării sau pentru luarea măsurilor solicitate;
                    <br/>
                    (ii) fie să refuze să dea curs cererii.
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{fontFamily: 'Kanit, sans-serif', textAlign: 'justify'}}
                >
                    Precizăm faptul că prin acceptarea acestui acord, datele Dvs. personale vor fi prelucrate de ALLPRO
                    SERVICE S.R.L conform prezentei Politici de Prelucrare a Datelor cu Caracter Personal.
                    <br/><br/>
                    Vă mulțumim!
                </Typography>
            </Box>
        </div>
    );
};

