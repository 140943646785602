import React, {useState, useEffect, useRef, forwardRef} from 'react';
import {
    Center,
    ContainerSX,
    DescriereSectiuneSX,
    SectiuneTitluFontSX,
} from "../App.styles";
import {Box, Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import backgroundImage from "../imaginiSite/welding-7104637_1920.jpg";
import {createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width:600px)': {
                        paddingTop: '0 !important',
                        paddingBottom: '0 !important',
                    },
                    '@media (min-width:0px)': {
                        paddingTop: '0 !important',
                        paddingBottom: '0 !important',
                    },
                },
            },
        },
    },
});

export const Despre = forwardRef((props, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.2, // Adjust this threshold as needed
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div ref={ref} style={{...Center, height: 'auto'}}>
                <Container
                    sx={{
                        ...ContainerSX,
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundAttachment: 'fixed',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        height: 'auto',
                        backgroundPosition: '170px -15%',
                    }}
                    maxWidth={false}
                    disableGutters={true}
                >
                    <Grid container>
                        <Grid item xs={12} md={6}
                              sx={{textAlign: "justify", backgroundColor: '#EEEEEE', height: 'auto'}}>
                            <Box ref={sectionRef} style={{ marginTop: '40px', marginBottom: '40px' }}>
                                <Box paddingLeft={"50%"} paddingRight={"5%"} width='100%'
                                     style={{
                                         transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
                                         transition: isVisible ? 'transform 1.5s ease' : 'transform 1s ease 1s', // delay o secunda la tranzitie
                                     }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary"
                                                style={{...SectiuneTitluFontSX, position: 'relative', left: '-2px'}}>
                                        Despre
                                    </Typography>
                                    <Box sx={{
                                        width: '100px',
                                        height: '5px',
                                        backgroundColor: '#00ADB5',
                                        position: 'relative',
                                        // // left: '20%',
                                        // // si si cu linia asta ma razboiesc aici
                                        // transform: 'translateX(-50%)',
                                        marginTop: '8px'
                                    }}/>
                                    <br/>
                                    <Typography variant="body1" color="text.secondary" style={DescriereSectiuneSX}>
                                        Allpro Service este o companie cu capital integral privat și românesc, înființată în 2005.
                                        Organizația a crescut constant, acționând ca un partener de încredere în proiectele incredințate,
                                        fiind în măsură să furnizeze printre cele mai bune servicii în domeniul confecțiilor metalice.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body1" color="text.secondary" style={DescriereSectiuneSX}>
                                        Misiunea noastră este de a oferi clienților noștri soluții de calitate, oferindu-le
                                        consultanță specializată, soluții personalizate și un serviciu impecabil.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body1" color="text.secondary" style={DescriereSectiuneSX}>
                                        Principalele valori după care ne ghidăm și care ne definesc sunt:
                                        <ul>
                                            <li>Satisfacția clienților</li>
                                            <li>Calitate</li>
                                            <li>Promtitudine</li>
                                            <li>Seriozitate</li>
                                            <li>Perseverență</li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </ThemeProvider>
    )
})