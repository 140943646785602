import React, { useState, memo } from 'react';
import { TextField, Button, Typography, Grid, Box, FormHelperText } from '@mui/material';
import { DescriereSectiuneSX } from "../App.styles";
import axios from "axios";

const ContactForm = memo(() => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const [errors, setErrors] = useState({
        name: false,
        email: false,
        phone: false,
        message: false
    });

    const handleSubmit = () => {
        if (isValidForm()) {
            const contactFormDTO = {
                name: name,
                email: email,
                phone: phone,
                message: message,
            };

            axios.post(`${process.env.REACT_APP_SERVER_LINK}/mail/sendMail`, contactFormDTO, {
                headers: {
                    "content-type": "application/json"
                }
            }).then((response: any) => {
                // Reset fields after a successful request
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                // Optionally, you could also reset errors here
                setErrors({
                    name: false,
                    email: false,
                    phone: false,
                    message: false
                });
            });
        }
    };

    const isValidForm = () => {
        return validateName(name) && validateEmail(email) && validatePhone(phone) && message.trim() !== "";
    };

    const validateName = (name) => {
        const nameRegex = /^[A-Za-zăâîșțĂÂÎȘȚ\s]+$/;
        return nameRegex.test(name.trim());
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[+]?[0-9]{7,15}$/;
        return phoneRegex.test(phone.trim());
    };

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        switch (field) {
            case 'name':
                setName(value);
                setErrors({ ...errors, name: !validateName(value) });
                break;
            case 'email':
                setEmail(value);
                setErrors({ ...errors, email: !validateEmail(value) });
                break;
            case 'phone':
                setPhone(value);
                setErrors({ ...errors, phone: !validatePhone(value) });
                break;
            case 'message':
                setMessage(value);
                setErrors({ ...errors, message: value.trim() === "" });
                break;
            default:
                break;
        }
    };

    const textFieldProps = {
        sx: {
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#222831',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#00ADB5',
                },
            },
            '& .MuiFormLabel-root': {
                color: '#222831',
                '&.Mui-focused': {
                    color: '#00ADB5',
                },
            },
        },
    };

    return (
        <Box sx={{ width: '100%', margin: '20px auto' }}>
            <Typography variant="h5" gutterBottom color="text.primary" style={DescriereSectiuneSX}>
                Contactează-ne
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        fullWidth
                        label="Nume"
                        name="name"
                        type="text"
                        value={name}
                        onChange={(e) => handleInputChange(e, 'name')}
                        error={errors.name}
                        helperText={errors.name ? "Introduceți un nume valid." : ""}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => handleInputChange(e, 'email')}
                        error={errors.email}
                        helperText={errors.email ? "Introduceți un email valid." : ""}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        fullWidth
                        label="Telefon"
                        name="telefon"
                        value={phone}
                        onChange={(e) => handleInputChange(e, 'phone')}
                        error={errors.phone}
                        helperText={errors.phone ? "Introduceți un număr de telefon valid." : ""}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        fullWidth
                        label="Mesaj"
                        name="message"
                        type="text"
                        value={message}
                        onChange={(e) => handleInputChange(e, 'message')}
                        error={errors.message}
                        helperText={errors.message ? "Mesajul nu poate fi gol." : ""}
                        required
                        multiline
                        rows={4}
                    />
                    <FormHelperText>* - Câmpuri obligatorii</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{backgroundColor: '#00ADB5', color: '#EEEEEE'}}
                        onClick={handleSubmit}
                        disabled={!isValidForm()}
                    >
                        Trimite
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
});

export default ContactForm;
